import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Row, Column } from '../../layout/Grid'
import DoubleTitle from '../DoubleTitle'
import Button from '../Button'
import * as Styled from './SectionDocs.styles'
import IconCertyfikat from '../../images/icons/certyfikat.svg'

const SectionDocs = () => {
  return (
    <Styled.Section>
      <Row alignMiddle>
        <Column xs={12} lg={5}>
          <DoubleTitle
            title="Rozwijamy się&nbsp;dla&nbsp;Ciebie"
            border="addon"
            noBorderLarge
          />
        </Column>
        <Column xs={12} lg={7}>
          <p className="lead">
            Nieustannie dążymy do perfekcji. Dbamy o stały rozwój Pracowników i
            firmy. Nagrody i wyróżnienia, to dla nas zarówno powód do dumy, ale
            również motywacja do dalszej wytężonej pracy.
          </p>
        </Column>
      </Row>
      <Row column>
        <Styled.DocsWrapper>
          <Styled.DocWrapper>
            <a href="/certyfikaty/KRD-rzetelna-firma.pdf" target="_blank">
              <Styled.ImgWrapper>
                <StaticImage
                  src="../../images/home/docs/rzetelna-firma.jpg"
                  alt="Rzetelna firma"
                />
              </Styled.ImgWrapper>
              <p className="lead">
                Certyfikat <br />
                rzetelności
              </p>
            </a>
          </Styled.DocWrapper>
          <Styled.DocWrapper>
            <a
              href="/certyfikaty/KRS-Nordic-Logistics-Polska.pdf"
              target="_blank"
            >
              <Styled.ImgWrapper>
                <StaticImage src="../../images/home/docs/krs.jpg" alt="" />
              </Styled.ImgWrapper>
              <p className="lead">
                Certyfikat <br />
                KRS
              </p>
            </a>
          </Styled.DocWrapper>
          <Styled.DocWrapper>
            <a href="/certyfikaty/KRD-Certyfikat.pdf" target="_blank">
              <Styled.ImgWrapper>
                <StaticImage src="../../images/home/docs/krd.jpg" alt="" />
              </Styled.ImgWrapper>
              <p className="lead">
                Certyfikat <br />
                KRD
              </p>
            </a>
          </Styled.DocWrapper>
          <Styled.DocWrapper>
            <a
              href="/certyfikaty/Trans-TCF-Certyfikat-Nordic-Logistics.pdf"
              target="_blank"
            >
              <Styled.ImgWrapper>
                <StaticImage src="../../images/home/docs/tcf.jpg" alt="" />
              </Styled.ImgWrapper>
              <p className="lead">
                Certyfikat <br />
                TCF
              </p>
            </a>
          </Styled.DocWrapper>
          <Styled.DocWrapper>
            <a
              href="/certyfikaty/Licencja-transportu-drogowego.pdf"
              target="_blank"
            >
              <Styled.ImgWrapper>
                <IconCertyfikat />
              </Styled.ImgWrapper>
              <p className="lead">Licencja</p>
            </a>
          </Styled.DocWrapper>
          <Styled.DocWrapper>
            <a href="/certyfikaty/Polisa-OCP.pdf" target="_blank">
              <Styled.ImgWrapper>
                <StaticImage src="../../images/home/docs/pzu.jpg" alt="" />
              </Styled.ImgWrapper>
              <p className="lead">Polisa OCP</p>
            </a>
          </Styled.DocWrapper>
          <Styled.DocWrapper>
            <a href="/certyfikaty/Polisa-OCS.pdf" target="_blank">
              <Styled.ImgWrapper>
                <StaticImage src="../../images/home/docs/pzu.jpg" alt="" />
              </Styled.ImgWrapper>
              <p className="lead">Polisa OCS</p>
            </a>
          </Styled.DocWrapper>
        </Styled.DocsWrapper>
      </Row>
      <Row column largeTextCenter>
        <Button primary page="/o-nas">
          Czytaj więcej
        </Button>
      </Row>
    </Styled.Section>
  )
}

export default SectionDocs
